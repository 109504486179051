<template>
    <!--登录页-->
    <div class="bg">
        <div class="title_div">供应商查询系统</div>
        <img src="@/assets/image/logo.png" style="height:50px;width:46px;margin-top:3vh;">
        <div style="font-size:18px;margin-top:2vh;">欢迎{{pagetype==1?'登录':'注册'}}供应商查询系统</div>
        <!--登录窗-->
        <div class="card_body">
            <div style="display:flex;justify-content:center;margin-bottom:3vh;margin-top:3vh;">
                <div class="every_input">
                    <img src="@/assets/icon/shoujihao.png" style="height:20px;width:20px;margin-right:2vw;">
                    <input type="text" v-model="mobile" placeholder="账号" class="login_input" >
                </div>
            </div>
            <div style="display:flex;justify-content:center;margin-bottom:5vh;">
                <div class="every_input">
                    <img src="@/assets/icon/mima.png"  style="height:20px;width:20px;margin-right:2vw;">
                    <input type="password"  v-model="password" placeholder="密码" class="login_input" >
                </div>
            </div>
            <div style="display:flex;flex-direction: column;align-items:center;justify-content:center;">
                <div v-if="pagetype==1" class="login_btn" @click="login_api()">登录</div>
                <div v-if="false" class="register_btn" @click="changePage(2)">注册</div>
                <div v-if="pagetype==2" class="login_btn" @click="register_api()">确认注册</div>
                <div v-if="pagetype==2" class="register_btn" @click="changePage(1)">返回登录</div>
                <div style="height:20px;"></div>
            </div>
        </div>
        <!-- 下边字 -->
        <div style="margin-top:10vh;display:flex;align-items:center;">
            <div class="bottom_line"></div>
            <div class="bottom_font">定战场</div>
            <div class="bottom_font">选精英</div>
            <div class="bottom_font">聚资源</div>
            <div class="bottom_font">打胜仗</div>
            <div class="bottom_line"></div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        mobile:'',
        password:'',
        pagetype:1,
        loading:'',
    };
  },
  mounted(){
      if(this.$route.query.pagetype==1){
        this.showLoading();
        this.$message({
            message: '登录失效，请重新登录',
            type:'warning'
        });
        this.loading.close();
      }
  },
  methods:{
       //loading
       showLoading(){
          this.loading = this.$loading({
             lock: true,
             text: '查询中...',
             spinner: 'el-icon-loading',
             background: 'rgba(0, 0, 0, 0.7)'
           });
       },
      //注册登录切换
      changePage(e){
          this.password = '';
          this.mobile = '';
          this.pagetype = e;
      },
      //登录
      login_api(){
          this.$axios.post(this.$api.login_api,{
          
              mobile:this.mobile,
              password:this.password,
              
          },).then(res=>{
            console.log(this.mobile,"this.mobile")
              if(res.data.code==0){
                localStorage.setItem("token",res.data.token);
                localStorage.setItem("mobile",this.mobile);
                this.$router.push('./index2');
              }else{
                this.$message({
                    message: res.data.msg,
                    type: 'warning'
                });
              }
          })
      },
      //注册
      register_api(){
            this.$axios.post(this.$api.register_api,{
              mobile:this.mobile,
              password:this.password,
            }).then(res=>{
                if(res.data.code==0){
                    this.$message({
                      message: '注册成功',
                      type: 'success'
                  });
                  this.mobile = '';
                  this.password = '';
                  this.pagetype = 1;
                }else{
                  this.$message({
                      message: res.data.msg,
                      type: 'warning'
                  });
                }
            })
      },
  }
}
</script>
<style scoped>
.bg{
    height:100vh;
    width:100vw;
    background: radial-gradient(circle at 50%, #b0daf3, #FFFFFF);
    /* background-image: linear-gradient(-113deg,#FFFFFF, #4EC5FB); */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}
.title_div{
  height:9vh;
  background-image: linear-gradient(#3F98EB, #006ED5 );
  line-height:12vh;
  color:#FFFFFF;
  text-align: center;
  font-size:4.6vw;
  width:100vw;
}
.card_body{
    margin-top:4vh;
    width:86vw;
    border-radius:20px;
    -webkit-box-shadow: #d1d0d0 0px 0px 20px; 
    -moz-box-shadow: #d1d0d0 0px 0px 20px; 
    box-shadow: #d1d0d0 0px 0px 20px;
    background-color: #FFFFFF;
}
.every_input{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e0e0;;
}
.login_btn{
    height:40px;
    background-color:#1d60f0;
    border: 1px solid #1d60f0;
    color:#FFFFFF;
    text-align: center;
    line-height: 40px;
    width:70vw;
    border-radius: 20px;
    margin-bottom:1.6vh;
}
.register_btn{
    height:40px;
    color:#afaeae;
    border: 1px solid #e2e0e0;
    text-align: center;
    line-height: 40px;
    width:70vw;
    border-radius: 20px;
    margin-bottom:3vh;
}
.login_input{
  border: none;
  width:60vw;
  margin-top:1vh;
  height:30px;
  border-radius:5px;
  padding-left:3vw;
}
 input::-webkit-input-placeholder{
    color:#afaeae;
}
.bottom_font{
    font-style:italic;
    font-weight:800;
    color:#b7b5b5;
    font-size:4vw;
    margin:5px;
}
.bottom_line{
    background-color:#b7b5b5;
    width:14vw;
    height:1px;
}
</style>